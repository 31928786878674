import { computed, reactive, watch, ref, onMounted, onUnmounted } from "vue";
import { useField, useForm } from "vee-validate";
import { useAlertStore, useAuth } from "../../stores";
import { useRouter } from "vue-router";
import VOtpInput from "vue3-otp-input";
import { verifyLoginOTP } from "../../Actions/AuthActions";
import timerImage from "@/../assets/images/timer.svg";
import { useMetaStore } from "../../stores";

export default {
  components: {
    VOtpInput,
  },
  data() {
    return {
      phoneEnable: false
    }
  },
  setup() {
    const authStore = useAuth();
    const alertStore = useAlertStore();
    const router = useRouter();
    const user = computed({
      get: () => authStore.user,
    });
    const otpFilled = ref("");
    const otpVerified = ref(false);
    const countDown = ref(59);
    const otpSent = ref(false);
    const bindModal = ref("");
    const metaStore = useMetaStore();
    const marketplaceContent = computed(() => metaStore.marketplaceContent);
    const marketplaceName = computed(() => metaStore.marketplaceName);

    const otpInput = ref("");
    const handleOnChange = (value) => {
      bindModal.value = value;
      otpInput.value = value;
      otpFilled.value = value.length >= 6;
    };

    const getTimerImage = () => {
      return timerImage;
    }
    
    const userInput = computed({
      get: () => authStore.forgotPasswordUser,
    });

    const resendOTP = () => {
      otpInput.value?.clearInput();
      authStore.mobileSignIn(userInput.value);
      countDown.value = 59;
    };


    const dialog = computed({
      get: () => authStore.dialog,
      set: (value) => authStore.setLoginDialog(value),
    });

    const dataState = reactive({
      isDisabled: true,
      checkbox: true,
    });
    const checkboxError = computed(() =>
      dataState.checkbox ? "" : "Must be checked."
    );

    const { values, handleSubmit, handleReset, errors } = useForm({
      validationSchema: {
        password(value) {
          if (value?.length >= 2) return true;

          return "Password can not be blank.";
        },

        phone(value) {
          const isFirstCharNum = (str = '') => !isNaN(str.charAt(0));
          if (isFirstCharNum(value) ? ((value?.length === 10) && /^\d+$/.test(value)) : (value?.length >= 3)) return true;
          return isFirstCharNum(value) ? "Phone number needs to be of 10 digits." : "Username needs to be of minimum 3 characters";
        },
      },
    });

    const password = useField("password");
    const phone = useField("phone");
    const passwordVisible = ref(false);
    const handleFormSubmit = ({ values, errors }) => {
      let userDetails;
      if (
        ((errors.password) && !otpSent.value) ||
        ((errors.phone) && !otpSent.value) ||
        checkboxError.value
      ) {
        return;
      } else if (!otpSent.value) {
        userDetails = {
          mobile: values.phone,
          password: values.password,
      }};
      
      if (otpSent.value && bindModal.value) {
        verifyLoginOTP({ otp: bindModal.value, mobile: values.phone, transaction_token: authStore.transactionToken }).then((res) => {
          if (res.status === 200) {
            otpVerified.value = true;
            setTimeout(() => {
              authStore.setUser(res.data.user);
              authStore.setOtpLoginDialog(false);
              router.push({ name: "Home" });
              otpInput.value?.clearInput();
              bindModal.value = "";
            }, 500)
          } else {
            const metaStore = useMetaStore();
            const marketplaceContent = metaStore.marketplaceContent;
            if(marketplaceContent === 'private'){
              alertStore.setAlertMessage("error", res?.data.errors, true);
            }else{
              alertStore.setAlertMessage("error", res.data?.message, true);
            }
          }
      })} else if(!otpSent.value && userDetails?.mobile && userDetails.password) {
        authStore.signIn(userDetails);
      }
    };

    const submit = handleSubmit(async (values) => {
    handleFormSubmit({values, errors: {}})}, handleFormSubmit);

    const handlePasswordLogin = () => {
      otpSent.value = false;
      otpInput.value = "";
      bindModal.value = "";
    }

    const sendOtp = async () => {
      password.setValue('')
      const resp =  await authStore.mobileSignIn(phone.value.value);
      if(resp?.data?.status_code === 200) {
        otpSent.value = true;
      }
    }

    const handleForgotPassword = () => {
      authStore.setLoginDialog(false);
      authStore.setForgotPasswordDialog(true);
    };

    const handleSignOut = () => {
      authStore.signOut();
      const metaStore = useMetaStore();
      const marketplaceContent = metaStore.marketplaceContent;
      const marketplaceName = metaStore.marketplaceName;
      if(marketplaceContent === 'private'){
        router.push({ 
          name: "MetaProducts", 
          params: { marketplaceName }
         });
      }else {
        router.push({ name: "Home" });
      }
    };

    let interval;

    watch(dialog, () => {
      otpVerified.value = false;
      dataState.isDisabled = true;
      if(otpInput.value && otpInput.value.clearInput) {
        otpInput.value?.clearInput();
      }
      handleReset();
      otpSent.value = false;
      bindModal.value = "";
      countDown.value = 59;
    })

    watch(user, () => {
      if (user?.value?.authentication_token) {
        window.location.reload();
        authStore.setLoginDialog(false);
      }
    });

    watch(otpSent, () => {
      countDown.value = 59;
    });

    watch(otpFilled, () => {
      if(otpFilled.value === true) {
        submit();
      }
    });

    onMounted(() => {
      interval = setInterval(() => {
        if (countDown.value > 0) {
          countDown.value = countDown.value - 1;
        }
      }, 1000);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    const passwordVisibility = () => {
      passwordVisible.value = !passwordVisible.value;
    };

    const handleChangePassword = () => {
      authStore.setChangePasswordDialog(true);
    };

    return {
      user,
      otpInput,
      bindModal,
      handleOnChange,
      otpFilled,
      passwordVisibility,
      password,
      handleChangePassword,
      countDown,
      phone,
      resendOTP,
      sendOtp,
      otpSent,
      getTimerImage,
      checkboxError,
      submit,
      handleReset,
      otpVerified,
      handlePasswordLogin,
      handleSignOut,
      passwordVisible,
      dataState,
      dialog,
      otpInput,
      authStore,
      handleForgotPassword,
      marketplaceContent,
      marketplaceName
    };
  },
  methods: {
    handleChange(e) {
      this.phoneEnable = e.target.value.length === 10 && /^\d+$/.test(this._.setupState.phone.value.value);
    },
    handleRedirect(componetName) {
      this.authStore.setLoginDialog(false);
      this.$router.push({ name: componetName });
    },
  },
};