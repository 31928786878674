<template>
  <div class="details-box">
    <p class="qty d-flex align-start text-start mb-2 text-subtitle-1">
      Quantity:&nbsp;
      <b>
        {{ getValue("items_count", 0) }}
        {{ getValue("items_count", 0) > 1 ? 'items' : 'item' }}
        </b
      >
    </p>
    <p class="mrp d-flex align-start text-start mb-2 text-subtitle-1">
      {{configuration.amountLabel}}:&nbsp; <b>{{lotSummary.currencySymbol}} {{ formatCurrency(getValue("mrp")) }} </b>
    </p>
    <p class="mrp d-flex align-start text-start mb-2 text-subtitle-1">
      Current Bid:&nbsp; <b>{{lotSummary.currencySymbol}} {{ formatCurrency(getValue("currentBid")) }} </b>
    </p>

    <p class="bid d-flex align-start text-start mb-4 text-subtitle-1" v-if="user?.authentication_token">
      My Bid:&nbsp;
      <b v-bind:class="getValue('color')">
        {{ getValue("bid_price") }}&nbsp;{{ getRank() }}
      </b>
    </p>
  </div>
  <div class="d-flex justify-space-between mt-2 mb-2">
    <p>
      <v-icon size="x-large" icon="mdi-truck-outline" color="blue"></v-icon>
      {{getValue('shipping')}}
    </p>
  </div>
  <template v-if="bid_not_started">
    <v-btn color="blue-darken-1 flex-grow-1 mb-2" size="large" width="100%">
      Bid not started
    </v-btn>
  </template>
  <template v-else-if="!bid_finished">
    <div class="mt-3">
      <v-btn
        v-if="!isBidEqualToBuy()"
        color="blue-darken-1 flex-grow-1 mb-2"
        size="large"
        width="100%"
        class="bid-buy-button text-caption mt-2 mb-2"
        variant="flat"
        @click="handleBidBuyConfirm('bid')"
        >Bid @{{lotSummary.currencySymbol}} {{ formatCurrency(placeBidDetail.currentBidPrice) }} 
        {{configuration.showMRP ? `(Save ${ placeBidDetail.bidDiscount }%)` : null}}
      </v-btn>

      <v-btn
        color="blue"
        variant="outlined"
        class="bid-buy-button text-caption mb-2"
        size="large"
        width="100%"
        @click="handleBidBuyConfirm('buy')"
      >
        Buy @{{lotSummary.currencySymbol}} {{  formatCurrency(getValue("buy_now_price", "N/A")) }} &nbsp;
        <b>{{configuration.showMRP ? `(Save ${getValue("offPercentage", 0)}%)` : null}}</b>
      </v-btn>
    </div>
  </template>

  <template v-else-if="bid_finished">
    <v-btn color="blue-darken-1 flex-grow-1 mb-2" size="large" width="100%" :disabled="disabledBtn">
      Bid Expired
    </v-btn>
  </template>
  <v-btn color="green-darken-1 flex-grow-1 mb-2" size="large" width="100%" @click="setShareActive">
    <v-icon size="x-large" icon="mdi-share" color="white"></v-icon>
    Share
  </v-btn>
</template>

<script setup>
import { defineProps, ref, watch, computed } from "vue";
import {
  uselotProducts,
  useAuth,
  useMetaStore
} from "../../../stores";
import {
  statusList,
} from "../../../static";
import {
  formatNumber,
  savePercent,
  formatCurrency,
} from "../../../misc/formatter";
import configuration from "../../../config.json";

const authStore = useAuth();
const user = computed({
  get: () => authStore.user,
});
const metaStore = useMetaStore();
const metaContent = metaStore.marketplaceContent;
const disabledBtn = metaContent == 'private' ? true : false;
const lotStore = uselotProducts();
const lotSummary = computed(() => lotStore.lotSummary);
let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
let EndBidTime = new Date(lotSummary.value?.end_date).getTime();

const bid_finished = ref(
  lotSummary.value?.status === statusList.InProgress ||
  lotSummary.value?.status === statusList.InProgress_2 ||
  lotSummary.value?.status === statusList.NotStarted
    ? !lotSummary.value.bidRemainingTime
    : true
);

const bid_not_started = ref(
    lotSummary.value?.status === statusList.NotStarted || lotSummary.value.futureBid
);

const props = defineProps({
  getValue: Object,
  setShareActive: Function,
  getRank: Object,
  lotDetailState: Object,
  isBidEqualToBuy: Function,
  handleBidBuyConfirm: Function,
});

const placeBidDetail = computed(() => {
  let highest_price = formatNumber(lotSummary.value?.highest_price);
  let floor_price = formatNumber(lotSummary.value?.floor_price);
  let increment_slab = formatNumber(lotSummary.value?.increment_slab);
  let bid_price = formatNumber(lotSummary.value?.bid_price);
  let price = highest_price ? highest_price + increment_slab : (increment_slab + floor_price);
  let discount = lotSummary.value?.mrp
    ? savePercent(formatNumber(lotSummary.value.mrp), price)
    : 0;
  return { currentBidPrice: price || 0, bidDiscount: discount };
});

watch(() => ({...lotSummary.value}), async (newValue, oldValue) => {
  if (lotSummary.value?.id) {
    let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
    let EndBidTime = new Date(lotSummary.value?.end_date).getTime();
    bid_not_started.value =
      lotSummary.value?.status === statusList.NotStarted ||
      lotSummary.value.futureBid;
    bid_finished.value =
      lotSummary.value?.status === statusList.InProgress ||
      lotSummary.value?.status === statusList.InProgress_2 ||
      lotSummary.value?.status === statusList.NotStarted
        ? !lotSummary.value.bidRemainingTime
        : true;
  }
});

const {
  getValue = () => {},
  getRank = () => {},
  lotDetailState,
  setShareActive = () => {},
  isBidEqualToBuy = () => {},
  handleBidBuyConfirm = () => {},
} = props;

</script>
