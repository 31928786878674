<template>
  <v-dialog
    v-model="dialog.popUp"
    class="lotdetails-dialog"
    :max-width="dialog?.width || 1100"
  >
    <v-card v-if="!loading">
      <v-card class="pa-5" v-if="dialog.type === 'ConfirmBidBuy'">
        <v-btn icon variant="text" @click="dialog.popUp = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title
          class="d-flex justify-center align-center text-center dialog-heading"
          >{{ dialog.title }}</v-card-title
        >
        <v-card-text
          class="d-flex justify-center align-center text-center dialog-description"
          >{{ dialog.text }}</v-card-text
        >
        <v-card-actions class="d-flex justify-center align-center text-center">
          <v-btn @click="closeCongratulationsDialog">No</v-btn>
          <v-btn @click="handleBidSubmit" class="bg-theme-primary">Yes</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="pa-5" v-else-if="dialog.type === 'successBid'">
        <v-card-title
          class="d-flex justify-center align-center text-center success-heading"
          >Congratulations!</v-card-title
        >
        <v-card-text
          class="d-flex justify-center align-center text-center dialog-description"
          >Bid successfully placed for {{lotSummary.currencySymbol}} {{ formatCurrency(dialog.bidPrice) }}</v-card-text
        >
        <v-card-actions class="d-flex justify-center align-center text-center">
          <v-btn @click="closeCongratulationsDialog" class="bg-theme-primary"
            >ok</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-card class="buy-summary" v-else-if="dialog.type === 'buy_summary'">
        <v-card-title>Buy Now</v-card-title>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12" lg="4" sm="12">
              <v-carousel
                hide-delimiter-background
                height="auto"
                hide-delimiters="true"
                continuous
                cycle={true}
                class="buy-carousel"
              >
                <TimerBadge
                  :remainingTime="lotSummary.bidRemainingTime" 
                  :endDate="buyNowSummary?.lot_publish?.end_date || new Date()"
                  class="timer"
                ></TimerBadge>
                <v-carousel-item
                  v-for="(item, i) in image"
                  :key="i"
                  :src="item"
                  cover
                  style="color: s #bdbdbd"
                ></v-carousel-item>
              </v-carousel>
              <v-table density="compact" class="table-border mt-2 order-summary">
                <thead>
                  <tr>
                    <th class="text-left head" colspan="2">Lot Summary</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Order Amount</td>
                    <td>
                      {{lotSummary.currencySymbol}} {{ formatCurrency(buyNowSummary?.lot_publish?.buy_now_price || dialog.price) || "N/A" }}
                    </td>
                  </tr>
                  <tr>
                    <td>Total</td>
                    <td>
                      {{lotSummary.currencySymbol}} {{ formatCurrency(buyNowSummary?.lot_publish?.buy_now_price || dialog.price) || "N/A" }}
                    </td>
                  </tr>
                  <tr>
                    <td>Payable Amount</td>
                    <td>
                      {{lotSummary.currencySymbol}} {{ formatCurrency(buyNowSummary?.lot_publish?.buy_now_price || dialog.price) || "N/A" }}
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
            <v-col cols="12" lg="8" sm="12">
              <div>
                <p>
                  <a>{{ buyNowSummary?.lot_publish?.lot_name }}</a>
                </p>
                <h1 class="order-amount mt-3">
                  {{lotSummary.currencySymbol}} {{ formatCurrency(buyNowSummary?.lot_publish?.buy_now_price || dialog.price) || "N/A"
                  }}<span class="badge-transparent">Order Amount</span>
                </h1>
                <div class="mt-4">
                  <span class="badge mr-2"
                    ><v-icon
                      size="large"
                      icon="mdi-map-marker"
                      color="black"
                      class="mr-2"
                    ></v-icon
                    >{{
                      buyNowSummary?.lot_publish?.storage_location || "N/A"
                    }}</span
                  >
                  <span class="badge"
                    ><v-icon
                      size="large"
                      icon="mdi-shield-search"
                      color="black"
                      class="mr-2"
                    ></v-icon
                    >{{ buyNowSummary?.lot_publish?.grade_name.replaceAll("Refurbished", "") }}</span
                  >
                </div>
                <p class="d-none mt-2">
                  <span class="badge-transparent"
                    ><v-icon
                      size="large"
                      icon="mdi-tag"
                      color="black"
                      class="mr-2"
                    ></v-icon
                    >Pay 100% (899) and get a cash back of 88</span
                  >
                </p>
              </div>
              <div class="pay-amount mt-5 mb-3">
                <p><b>Pay Amount through:</b></p>
                <div class="box">
                  <v-radio-group inline v-model="buybid.paymentType">
                    <v-radio
                      v-for="paymentOption in buyNowSummary?.payment_options"
                      :label="paymentOption.value"
                      :value="paymentOption.id"
                      color="primary"
                      :key="paymentOption.id"
                    ></v-radio>
                    <!-- <v-radio label="NEFT/RTGS/IMPS" value="NEFT/RTGS/IMPS" color="primary"></v-radio> -->
                  </v-radio-group>
                </div>
              </div>
              <v-table density="compact" class="table-border mt-5 order-summary">
                <thead>
                  <tr>
                    <th class="text-left head" colspan="2">Bank Details</th>
                  </tr>
                </thead>
 
                <tbody>
                  <tr v-if="configuration.bankConfigs.companyName.visible">
                    <td>{{configuration.bankConfigs.companyName.title}}</td>
                    <td>{{ buyNowSummary?.bank_details?.["Company Name"] ?  buyNowSummary?.bank_details?.["Company Name"] : buyNowSummary?.bank_details?.["Name"]}}</td>
                  </tr>

                  <tr v-if="configuration.bankConfigs.beneficiaryAc.visible">
                    <td>{{configuration.bankConfigs.beneficiaryAc.title}}</td>
                    <td>{{ buyNowSummary?.bank_details?.["Account Number"] }}</td>
                  </tr> 

                  <tr v-if="configuration.bankConfigs.ifscCode.visible">
                    <td>{{configuration.bankConfigs.ifscCode.title}}</td>
                    <td>{{ buyNowSummary?.bank_details?.["IFSC Code"] }}</td>
                  </tr>

                  <tr v-if="configuration.bankConfigs.branchName.visible">
                    <td>{{configuration.bankConfigs.branchName.title}}</td>
                    <td>{{ buyNowSummary?.bank_details?.["Branch Name"] }}</td>
                  </tr>

                  <tr v-if="configuration.bankConfigs.accountType.visible">
                    <td>{{configuration.bankConfigs.accountType.title}}</td>
                    <td>{{ buyNowSummary?.bank_details?.["Account Type"] }}</td>
                  </tr>

                  <tr v-if="configuration.bankConfigs.title.visible">
                    <td>{{configuration.bankConfigs.title.title}}</td>
                    <td>{{ buyNowSummary?.bank_details?.["Bank Name"] }}</td>
                  </tr> 
                </tbody>
              </v-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center align-center text-center">
          <v-btn @click="dialog.popUp = false" variant="outlined" color="blue"
            >CANCEL</v-btn
          >
          <v-btn @click="checkoutBuyBid" :disabled="!buybid.paymentType" class="bg-theme-primary">CHECKOUT</v-btn>
        </v-card-actions>
      </v-card>

      <v-card class="buy-success" v-else-if="dialog.type === 'buySuccessfully'">
        <!-- <v-card-title>Buy Now</v-card-title> -->
        <v-card-text class="heading text-center">
          Your order
          <span class="font-weight-bold"
            ><a>{{ order_details?.order?.order_number }}</a></span
          >
          is successfully placed.
        </v-card-text>
        <p class="text-center heading mb-2">
          Thank you for placing on order. Please Remit to the account details
          below.
        </p>
        <v-row class="mx-auto">
          <v-col>
            <v-table density="compact" class="table-border mt-5 order-summary">
              <thead>
                <tr>
                  <th class="text-left head" colspan="2">Bank Details</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="configuration.bankConfigs.companyName.visible">
                  <td>{{configuration.bankConfigs.companyName.title}}</td>
                  <td>{{ buyNowSummary?.bank_details?.["Company Name"] || buyNowSummary?.bank_details?.["Name"]}}</td>
                </tr>
                <tr v-if="configuration.bankConfigs.beneficiaryAc.visible">
                    <td>{{configuration.bankConfigs.beneficiaryAc.title}}</td>
                  <td>{{buyNowSummary?.bank_details?.["Account Number"]}}</td>
                </tr>
                <tr v-if="configuration.bankConfigs.ifscCode.visible">
                    <td>{{configuration.bankConfigs.ifscCode.title}}</td>
                  <td>{{ buyNowSummary?.bank_details?.["IFSC Code"] }}</td>
                </tr>
                <tr v-if="configuration.bankConfigs.branchName.visible">
                    <td>{{configuration.bankConfigs.branchName.title}}</td>
                  <td>{{ buyNowSummary?.bank_details?.["Branch Name"] }}</td>
                </tr>
                <tr v-if="configuration.bankConfigs.accountType.visible">
                    <td>{{configuration.bankConfigs.accountType.title}}</td>
                  <td>{{ buyNowSummary?.bank_details?.["Account Type"] }}</td>
                </tr>
                <tr v-if="configuration.bankConfigs.title.visible">
                  <td>{{configuration.bankConfigs.title.title}}</td>
                  <td>{{ buyNowSummary?.bank_details?.["Bank Name"] }}</td>
                </tr>                
              </tbody>
            </v-table>
          </v-col>
        </v-row>

        <v-card-text class="d-none">
          <p>Bank Details</p>
          <p>Bank Name :</p>
          <p>HDFC</p>
          <p>IFSC CODE :</p>
          <p>HSBC0560002</p>
          <p>Account Number :</p>
          <p>BLUBRICH12721657224</p>
          <p>Branch Name :</p>
          <p>GANDHI BAZAAR, BANGALORE</p>
          <P>Account Type :</P>
          <p>CURRENT</p>
          <p>Name :</p>
          <p>GREEN ENABLED IT SOLUTIONS PVT LTD</p>
        </v-card-text>

        <v-card-actions class="justify-center mt-5 mb-5">
          <v-btn @click="closeCongratulationsDialog()" class="bg-theme-primary"
            >Ok</v-btn
          >
          <v-btn @click="closeDialog('Products')" class="bg-theme-primary"
            >Continue Shopping</v-btn
          >
          <v-btn @click="closeDialog('MyOrders')" class="bg-theme-primary"
            >My Orders</v-btn
          >
        </v-card-actions>
      </v-card>

      <v-card v-else-if="dialog.type === 'manifest_popup'">
        <v-card-title class="d-flex justify-center align-center text-center">{{
          dialog.title
        }}</v-card-title>
        <v-card-text>{{ dialog.message }}</v-card-text>
        <v-card-actions class="d-flex justify-center align-center text-center">
          <v-btn @click="closeCongratulationsDialog" variant="outlined">ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>
    <v-card v-if="loading">
      <Skeleton />
    </v-card>
  </v-dialog>
</template>

<script setup>
import { computed, defineProps, reactive, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import Skeleton from "../Skeleton/index.vue";
import { uselotProducts, loaderStore, useMetaStore } from "../../stores";
import TimerBadge from "./TimerBadge.vue";
import lotImage from "@/../assets/images/Box.svg";
import {formatCurrency} from "../../misc/formatter";
import configuration from "../../config.json";

const lotStore = uselotProducts();
const lotSummary = computed(() => lotStore.lotSummary);
const order_details = computed(() => lotStore.order_details);
const buyNowSummary = computed(() => lotStore.buyNowSummary);
const metaStore = useMetaStore();
const router = useRouter();

const loader = loaderStore();
const { loading } = storeToRefs(loaderStore());


const props = defineProps({
  dialog: Object,
  closeLotDialog: Function,
  handleShowBuySummary: Function,
  param_bid_id: String,
});

const image = computed(() =>
  lotSummary.value.lot_image_urls.length > 0
    ? lotSummary.value.lot_image_urls
    : [lotImage]
);

const buybid = reactive({
  paymentType: "",
});

const onUnmounted = () => {
  buybid.paymentType = "";
}

const handleBidSubmit = () => {
  if (props.dialog.title === "Bid Confirmation") {
    let bidDetails = {
      id: lotSummary.value.id,
      bid_price: props.dialog.bidPrice,
    };
    lotStore.placeBid(bidDetails, props.param_bid_id);
  } else {
    props.handleShowBuySummary();
  }
};

const checkoutBuyBid = () => {
  let bidDdetail = {
    lot_publish_id: lotSummary.value.id,
    payment_mode: buybid.paymentType,
  };

  if(props.dialog?.lotType === 'moq') {
    bidDdetail.quantity = props.dialog.qty
  }

  if (buybid.paymentType) {
    lotStore.checkoutBuyBid(bidDdetail, props.param_bid_id);
  }
};

const closeDialog = (route, path = "") => {
  buybid.paymentType = "";
  let finalPath;
  if (metaStore.marketplaceContent === 'private' && route == "Products") {
    finalPath = `/${metaStore.marketplaceName}/products`;
  } else if(metaStore.marketplaceContent === 'private' && route == "MyOrders"){
    finalPath = `/${metaStore.marketplaceName}/my_orders?mode=Hybrid`;
  } else if(metaStore.marketplaceContent !== 'private' && route == "Products"){
    finalPath = `/products`;
  } else if(metaStore.marketplaceContent !== 'private' && route == "MyOrders"){
    finalPath = `/my_orders?mode=Hybrid`;
  }
  router.replace(finalPath);

  // props.closeLotDialog(finalPath);
}
const closeCongratulationsDialog = () => {
  lotStore.setSuccess();
  buybid.paymentType = "";
  props.closeLotDialog();
};

watch(buyNowSummary, (newValue, oldValue) => {
  if (newValue.payment_options) {
    let payment_id = newValue.payment_options.find((option) =>
      option.value.includes("NEFT")
    )?.id;
  }
});
</script>
