<template>
  <v-responsive>
    <v-app class="large-margin">
      <div class="ribbonbox" v-if="lotStore.amazonRibbon">
        <a href="#" @click="handleDialog('', $event)"><p class="ttl-ribbonbox ttl-ribbonbox2">Terms of purchase has been updated for Amazon lots, please <span class="cr-blue2">Click here</span> to know more. </p></a>
      </div>
      <template v-if="error.message && error.type === 'lotDetailsError' || user?.value?.authentication_token">
        <v-container fluid>
          <div class="error-container"> 
            <h1> Oops! </h1>
            <p>
              Sorry for the inconvenience but the page you are looking for is not available at the time.
            </p> 
            
            <p>
              Please explore some other auctions or contact support team.
            </p>
            <a :href="metaContent === 'private' ? `/${metaStore.marketplaceName}/products` : '/products'"> 
                Shop All Auctions
            </a>
            <!-- <p v-if="metaContent === 'private' && user?.value?.authentication_token">
              Please login and explore some other auctions or contact support team.
            </p> 
            <v-btn variant="outlined" color="#092D66" elevation="0" v-if="metaContent === 'private' && user?.value?.authentication_token" @click="userSignin">SignIn</v-btn> -->
          </div> 
        </v-container>
      </template>
      <template v-else-if="shouldShowContent">
        <div class="error-container mt-16" style="box-shadow: none !important;"> 
          <h3> Sign in with your account details </h3> 
          
          <p class="mt-8">
            Please login and explore some other auctions or contact support team.
          </p> 
          <v-btn variant="outlined" color="#092D66" elevation="0" @click="userSignin">SignIn</v-btn>
        </div>
      </template>
      <template v-else>
        <v-container fluid>
          <!--1st row 1st column Carousel -->
          <v-row v-if="!detailsLoader">
            <v-col cols="12" class="h-50" md="5" lg="5" xl="5" sm="12" xs="12">
              <v-carousel
                hide-delimiter-background
                class="sm-img-height"
                v-if="lotSummary?.id"
                continuous
                cycle={true}
                :show-arrows="lotDetailImage.length > 1"
                hide-delimiters="true"
              >
                <TimerBadge
                  class="timer-badge"
                  :remainingTime="lotSummary.bidRemainingTime"
                  :fetchDetails="fetchDetails"
                >
                </TimerBadge>
                <v-carousel-item
                  aspect-ratio="1"
                  v-for="(img, i) in lotDetailImage"
                  :key="i"
                  :src="img"
                  eager
                  class="lot-detail_slider-img"
                ></v-carousel-item>
              </v-carousel>
            </v-col>

            <!-- 1st row 2nd column lots details -->
            <v-col cols="12" md="5" lg="5" xl="5" sm="12" xs="12">
              <v-card variant="none" class="lot-details__card">
                <v-card-title
                  class="d-flex align-start text-start font-weight-bold text-wrap mb-3 sm-padding"
                >
                  {{ getValue("lot_name") }}
                </v-card-title>
                <v-card-actions class="sm-padding">
                  <v-btn
                    class="text-caption"
                    text-caption
                    variant="outlined"
                    rounded="lg"
                  >
                    <v-icon
                      size="small"
                      icon="mdi-map-marker"
                      color="blue"
                    ></v-icon>
                    {{ getValue("storage_location") }}
                  </v-btn>
                  <v-btn
                    class="ml-4 text-caption"
                    text-caption
                    variant="outlined"
                    rounded="lg"
                  >
                    {{ getValue("grade_name") }}
                  </v-btn>
                  <v-icon class="ml-4 recycle-icon" v-if="lotSummary.waste_lot == true" color="white">mdi-recycle</v-icon>
                </v-card-actions>

                <v-card-text class="sm-padding">
                  <!--  for open -->
                  <template v-if="queryParams.bid_id">
                    <OpenBidCard 
                      :getValue="getValue" 
                      :lotDetailState="lotDetailState"
                      :setShareActive="setShareActive"
                      :handleBidBuyConfirm="handleBidBuyConfirm"
                      :isBidEqualToBuy="isBidEqualToBuy"
                      :getRank="getRank" />
                  </template>

                  <!--  for hybrid -->
                  <template v-if="!queryParams.bid_id">
                    <template v-if="lotSummary.lot_type === 'hybrid'">
                      <HybridBidCard 
                        :getValue="getValue" 
                        :getRank="getRank" 
                        :setShareActive="setShareActive"
                        :bidPriceCheck="bidPriceCheck"
                        :handleBidPrice="handleBidPrice"
                        :handleBidBuyConfirm="handleBidBuyConfirm"
                        :isBidEqualToBuy="isBidEqualToBuy"
                        :lotDetailState="lotDetailState"/>
                    </template>
                    <template v-else-if="lotSummary.lot_type === 'moq'">
                      <MOQ 
                        :getValue="getValue" 
                        :bidPriceCheck="bidPriceCheck"
                        :setShareActive="setShareActive"
                        :bid_not_started="bid_not_started" 
                        :bid_finished="bid_finished"
                        :handleBidBuyConfirm="handleBidBuyConfirm"/>
                    </template>
                  </template>
                </v-card-text>
              </v-card>
            </v-col>

            <!-- 1st row 3rd column feedback -->
            <Feedback
              :lotDetailState="lotDetailState"
              :feedBackOptions="feedBackOptions"
              :handleSubmitFeedBack="handleSubmitFeedBack"
            />
          </v-row>

          <v-row v-if="detailsLoader">
            <Skeleton />
            <Skeleton />
          </v-row>

          <!-- second row -->
          <v-row class="mt-8" ref="termConditionElement">
            <v-col cols="12" md="4" lg="4" xl="4" sm="12" xs="12">
              <v-card class="bck-color h-100" variant="flat" color="#fafbfc">
                <v-card-title
                  class="d-flex align-start text-start text-color font-weight-bold sm-padding"
                >
                  CONDITION TYPE
                </v-card-title>
                <v-card-text class="sm-padding">
                  <p class="lh-medium">
                    <b>{{lotSummary.grade_name}}</b> - {{getConditions()}}...
                    <br /> <br />Check detailed grade definitions
                    <a
                      @click="handleReadMore('itemConditionElement')"
                      class="cursor-pointer"
                      >here</a
                    >
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="4" xl="4" sm="12" xs="12">
              <v-card class="bck-color h-100" variant="flat" color="#fafbfc">
                <v-card-title
                  class="d-flex align-start text-start text-color font-weight-bold sm-padding"
                >
                  TERMS OF PURCHASE
                </v-card-title>
                <v-card-text class="sm-padding">
                  <p class="lh-medium">
                    <span
                      v-if="lotDetailState?.termsOfPurchase?.title"
                      class="text read-more"
                      v-html="lotDetailState.termsOfPurchase.title"
                    ></span>
                    <span
                      v-else
                      class="text read-more"
                    >Terms of Purchase</span>
                    
                    <a
                      @click="handleReadMore('termElement')"
                      class="cursor-pointer"
                      >Read More</a
                    >
                  </p>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" md="4" lg="4" xl="4" sm="12" xs="12">
              <v-card class="bck-color h-100" variant="flat" color="#fafbfc">
                <v-card-title
                  class="d-flex align-start text-start text-color font-weight-bold sm-padding"
                >
                  FAQ's
                </v-card-title>
                <v-card-text class="sm-padding">
                  <p class="lh-medium">
                    <p
                      v-if="lotDetailState?.FaQ?.title"
                      class="text read-more"
                      v-html="lotDetailState?.FaQ.title"
                    ></p>
                    <p class="read-more" v-else> Not Available </p>
                    <a
                      @click="handleReadMore('faqElement')"
                      class="cursor-pointer"
                      >Read More</a
                    >
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Additional Description -->
          <v-row>
            <v-col cols="12">
              <v-card variant="none" class="bck-color mt-9 d-flex align-start">
                <v-card-text class="sm-padding">
                  <v-card-title
                    class="d-flex align-start text-color font-weight-bold pl-0 mb-5"
                    >ADDITIONAL INFO
                  </v-card-title>
                  <p class="d-flex align-start text-color">Lot Description:</p>
                  <p class="d-flex align-start">
                    {{lotSummary.shortDesc}}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Lot Details -->

          <LotItems />

          <!-- Table -->
          <v-row class="mt-9">
            <v-col cols="12">
              <AllProducts v-if="lotSummary"/>
            </v-col>
          </v-row>

          <!-- Terms of Purchase -->
          <v-row>
            <v-col cols="12" ref="itemConditionElement">
              <template v-if="lotDetailState.termView.itemCondition">
                <itemCondition :handleReadMore="handleReadMore" />
              </template>
            </v-col>
            <v-col ref="termElement" cols="12" id="termsOFpurchase">
              <v-card
                v-if="lotDetailState.termView.terms_of_purchase"
                variant="none"
                title="Terms of Purchase"
                class="bck-color mt-9 align-start product-details"
              >
                <v-card-text class="content">
                  <div class="mb-2" v-html="lotDetailState?.termsOfPurchase?.organization_name"></div>
                  <span
                    v-if="lotDetailState?.termsOfPurchase?.terms_and_conditions"
                    class="text"
                    v-html="lotDetailState.termsOfPurchase.terms_and_conditions"
                  ></span>
                  <v-div v-else-if="['bb'].indexOf(lotSummary.marketplace_id) > -1">
                    <Tnc />
                  </v-div> 
                  <v-list lines="three" class="pa-0" v-else density="compact">
                    <v-list-item
                      v-for="item in lotDetailState.termsOfPurchase.data"
                      :key="item.title"
                      :title="item.title"
                    >
                      <div v-html="item.subtitle"></div>
                    </v-list-item>
                  </v-list>
                </v-card-text>
                <v-card-actions class="justify-end pr-4">
                  <a
                    @click="handleReadMore('termElement', true)"
                    class="cursor-pointer"
                    >Read Less</a
                  >
                </v-card-actions>
              </v-card>
            </v-col>

            <v-col cols="12" ref="faqElement" id="faq">
              <v-card
                v-if="lotDetailState.termView.faq"
                variant="none"
                title="FAQs"
                class="bck-color mt-9 align-start product-details"
              >
                <v-card-text class="content" v-if="lotDetailState.FAQ?.faq">
                  <div class="mb-2" v-html="lotDetailState.FAQ?.organization_name"></div>
                  <div class="mb-2" v-html="lotDetailState.FAQ?.faq_title"></div>
                  <div v-html="lotDetailState.FAQ?.faq"></div>
                </v-card-text>

                <v-card-text class="content faq-content" v-else>
                  <FaqPage />
                </v-card-text>
                <v-card-actions class="justify-end pr-4">
                  <a
                    @click="handleReadMore('faq', true)"
                    class="cursor-pointer"
                    >Read Less</a
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>

          <!-- dialog -->
          <ShareDialog
            :setShareActive="setShareActive"
            :dialog="shareDialogState.dialog"
            :lotName="lotSummary.lot_name"
          ></ShareDialog>

          <LotsDialog
            :dialog="lotDetailState.dialog"
            :closeLotDialog="closeLotDialog"
            :handleShowBuySummary="handleShowBuySummary"
            :param_bid_id="queryParams?.bid_id"
          ></LotsDialog>
        </v-container>
      </template>

      <v-dialog
        v-model="itemData.dialog"
        width="800"
        content-class="terms-modal"
      >
        <v-card>
          <v-card-title>
            <span class="text-h5"> Terms of Purchase</span>
            <v-btn icon variant="text" @click="itemData.dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-div class="tnc-container">
            <p v-html="itemData.tcData"></p>
          </v-div>
          <v-card-actions class="d-flex justify-center">
            <v-spacer></v-spacer>
            <v-btn variant="outlined" @click="handleAccept(false)">Back</v-btn>
            <v-btn variant="outlined" @click="handleAccept(false)">Skip</v-btn>
            <v-btn class="bg-blue" variant="text" @click="handleAccept(true)">
              Accept
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </v-responsive>
</template>

<script setup>
import { computed, onMounted, reactive, watch, ref, onUnmounted } from "vue";
import {
  uselotProducts,
  useAuth,
  loaderStore,
  useCommonStore,
  usePaginationStore,
  useMetaStore,
  useAlertStore
} from "../../stores";
import { storeToRefs } from "pinia";
import { useRoute, useRouter } from "vue-router";
import {
  termsOfPurchase,
  FAQ,
  itemConditions,
  feedBackOptions,
  statusList,
} from "../../static";
import LotsDialog from "./LotsConfirmDialog.vue";
import ShareDialog from "./ShareDialog.vue";
import TimerBadge from "./TimerBadge.vue";
import OpenBidCard from "./PriceCard/Open.vue";
import MOQ from "./PriceCard/MOQ.vue";
import HybridBidCard from "./PriceCard/Hybrid.vue";
import LotItems from "./LotItems.vue";
import Feedback from "./Feedback.vue";
import Tnc from "./../signUp/Tnc.vue"
import FaqPage from "./../Faq/index.vue";
import itemCondition from "../../static/itemCondition.vue";
import AllProducts from "./AllProducts.vue";
import Skeleton from "../Skeleton/index.vue";
import lotImage from "@/../assets/images/Box.svg";
import {fetchOrganisationTc} from "../../Actions/MarketPlaceActions";
import {
  formatNumber,
  savePercent,
  formatCurrency,
} from "../../misc/formatter";
import { isRefetch } from "../../misc/index";
import { fetchScbTermsOfPurchase, fetchScbFaq } from "@/Actions/MarketPlaceActions";
import configuration from "../../config.json";
import { getUserDetails } from "../../Actions/UserActions";

const lotStore = uselotProducts();
const authStore = useAuth();
const loader = loaderStore();
const commonStore = useCommonStore();
const paginationstore = usePaginationStore();
const { loading, detailsLoader } = storeToRefs(loaderStore());
const metaStore = useMetaStore()
const metaContent = computed(() => metaStore.marketplaceContent);
const route = useRoute();
const router = useRouter();
const lotSummary = computed(() => lotStore.lotSummary);
const error = computed(() => lotStore.error);
const getConditions = () => {
  const rec = itemConditions.find(a => a.title === lotSummary?.value?.grade_name)
  return rec?.description.slice(0,120) ?? "Mixed Grade, having items of different grades.";
}

const brands = computed(() => lotStore.top_Brand);
const categorys = computed(() => lotStore.top_Category);
const bid_finished = ref(false);
const bid_not_started = ref(false);
const termElement = ref(null);
const faqElement = ref(null);
const termConditionElement = ref(null);
const itemConditionElement = ref(null);
const queryParams = ref(route.query);
// const lotDetailsIsDisabled =  computed(()=>lotStore.error?.id === "Lot_details" ? lotStore.error?.message : "")
const user = computed({
  get: () => authStore.user,
});

const userSignin = () => {
  if (user?.value?.authentication_token) {
    authStore.signOut("");
  } else {
    authStore.setLoginDialog(true);
  }
};

const shouldShowContent = computed(() => {
  return metaStore.marketplaceContent === 'private' && !user?.value?.authentication_token;
});

const itemData = reactive({
  dialog: false,
  tcData: {}
});

const handleDialog = (value = "", event) => {
  loader.setLoading(true);
  event.preventDefault();
  fetchOrganisationTc(6209).then((res) => {
    if (res.status === 200) {
      itemData.tcData = res?.data?.terms_of_purchase.terms_and_conditions;
      setTimeout(() => {
        loader.setLoading(false);
      }, 1000);
      itemData.dialog = true;
    } else {
      loader.setLoading(false);
      alertStore.setAlertMessage("error", res.data?.errors, true);
    }
  });
};


const handleAccept = () => {
  itemData.dialog = false;
};

const bidPriceCheck = () => {
  const lastBidAmount = formatNumber(lotSummary.value?.bid_price) || lotSummary.value?.floor_price;
  if (
    lotDetailState.bidPrice &&
    lotDetailState.bidPrice > formatNumber(lastBidAmount) &&
    lotDetailState.bidPrice < formatNumber(lotSummary.value?.buy_now_price) &&
    lotDetailState.bidPrice % formatNumber(lotSummary.value?.bid_value) === 0
  ) {
    return "";
  } else {
    const isNotCorrect =
      lotDetailState.bidPrice % formatNumber(lotSummary.value?.bid_value) !== 0;
    const isLessThanfloor =
      lotDetailState.bidPrice <= formatNumber(lastBidAmount);
    const isGreaterToBuy =
      lotDetailState.bidPrice >= formatNumber(lotSummary.value?.buy_now_price);

    if (isNotCorrect) {
      if (isLessThanfloor || isGreaterToBuy) {
        return isLessThanfloor
          ? `should be multiple of ${formatCurrency(
              lotSummary.value?.bid_value
            )} and price should be greater than ${lastBidAmount}.`
          : `Should be multiple of ${formatCurrency(
              lotSummary.value?.bid_value
            )} and value should be less than ${formatCurrency(
              lotSummary.value?.buy_now_price
            )}.`;
      } else {
        return `Should be multiple of ${formatCurrency(
          lotSummary.value?.bid_value
        )}.`;
      }
    } else if (isLessThanfloor) {
      return `Price should be greater than ${lastBidAmount}.`;
    } else if (isGreaterToBuy) {
      return `Price should be less than ${formatCurrency(
        lotSummary.value?.buy_now_price
      )}.`;
    }
  }
};

const bid_price_error = computed(() =>
  lotStore.error?.id === "bid_price_error"
    ? lotStore.error?.message
    : lotDetailState.bidPrice
    ? bidPriceCheck()
    : ""
);

const bidTime = computed(() => {
  return ((lotSummary.value?.status === statusList.InProgress) || (lotSummary.value?.status === statusList.InProgress_2))
    ? (queryParams.value?.bid_id ? (new Date() > lotSummary.value?.start_date ? lotSummary.value?.end_date : lotSummary.value?.start_date) : lotSummary.value?.end_date)
    : bid_not_started.value
    ? lotSummary.value?.start_date
    : new Date()
});

const lotDetailImage = computed(() =>
  lotSummary.value.lot_image_urls.length > 0
    ? lotSummary.value.lot_image_urls
    : [lotImage]
);

const placeBidDetail = computed(() => {
  let highest_price = formatNumber(lotSummary.value?.highest_price);
  let floor_price = formatNumber(lotSummary.value?.floor_price);
  let increment_slab = formatNumber(lotSummary.value?.increment_slab);
  let bid_price = formatNumber(lotSummary.value?.bid_price);
  let price = highest_price ? highest_price + increment_slab : (floor_price + increment_slab);
  let discount = lotSummary.value?.mrp
    ? savePercent(formatNumber(lotSummary.value.mrp), price)
    : 0;
  return { currentBidPrice: price || 0, bidDiscount: discount };
});

const shareDialogState = reactive({
  dialog: {
    popUp: false
  },
})

const setShareActive = () => {
  shareDialogState.dialog.popUp = true;
}

const lotDetailState = reactive({
  bidPrice: "",
  termsOfPurchase: termsOfPurchase,
  FaQ: FAQ,
  faqAdded: false,
  termsAdded: false,
  termView: {
    terms_of_purchase: false,
    faq: false,
    itemCondition: false,
  },
  dialog: {
    type: "",
    popUp: false,
  },
  feedBack: "",
  buyer_feedback: "",
});

const isBidEqualToBuy = () => {
  if(queryParams.value?.bid_id) {
    return placeBidDetail?.value?.currentBidPrice >= lotSummary.value?.buy_now_price
  } else {
    const incrementValue = queryParams.value?.bid_id
      ? formatNumber(lotSummary?.value?.increment_slab)
      : formatNumber(lotSummary?.value?.bid_value);
    return (
      formatNumber(lotSummary.value?.buy_now_price) <=
      (formatNumber(lotSummary?.value?.bid_price) + incrementValue)
    );
  }

};

const getValue = (attrType, defaultVal = "N/A") => {
  if (attrType === "grade_name" && lotSummary.value?.[attrType]) {
    return lotSummary.value?.[attrType].split(" ")[0] === "Refurbished"
      ? "Good"
      : lotSummary.value?.[attrType];
  } else if (attrType === "bid_price") {
    return (lotSummary.value?.[attrType] && lotSummary.value?.[attrType] != '0')
      ? lotSummary.value.currencySymbol + ' ' + formatCurrency(lotSummary.value?.[attrType]) : 'Not Bidded';
  } else if(attrType === "benchmark_price"){
    return lotSummary.value?.[attrType] === 'mrp'
      ? configuration.amountLabel
      : lotSummary.value?.[attrType];
  }else {
    return lotSummary.value?.[attrType] || defaultVal;
  }
};
let detailsInterval;

const fetchDetails = (isInterval = false) => {
  if (queryParams.value?.bid_id) {
    lotStore.getOpenLotDetail(route.params.id, queryParams.value?.bid_id, isInterval);
  } else {
    if(metaStore.marketplaceContent === 'private' && !user?.value?.authentication_token){
      authStore.setLoginDialog(true);
    }else{
      lotStore.getlotDetail(route.params.id, isInterval);
    }
  }
};

onMounted(() => {
  if (user?.value?.authentication_token) {
    if(configuration.Lots.MOQ.enabled){
      lotStore.fetchCarts();
    }
    getUserData();
  }

  lotStore.setSuccess();
  lotDetailState.buyer_feedback = "";
  lotStore.setUUIDParams(queryParams.value?.uuid);
  fetchDetails();
});

onUnmounted(() => {
  lotStore.$reset();
  clearInterval(detailsInterval);
  clearInterval(lotInterval);
});

const handleBidBuyConfirm = (type, lotQty = 1, buyPrice = 0) => {
  lotStore.setError();
  if (user.value?.authentication_token || queryParams.value?.uuid) {
    if (type === "bid") {
      lotDetailState.dialog = {
        type: "ConfirmBidBuy",
        popUp: true,
        bidPrice: queryParams.value?.bid_id
          ? placeBidDetail.value.currentBidPrice
          : lotDetailState.bidPrice,
        title: "Bid Confirmation",
        text: `Are you sure you want to bid @ ${lotSummary.value.currencySymbol} ${
          formatCurrency(queryParams.value?.bid_id
            ? placeBidDetail.value.currentBidPrice
            : lotDetailState.bidPrice)
        }?`,
        width: "500",
      };
    } else {
      lotDetailState.dialog = {
        type: "ConfirmBidBuy",
        popUp: true,
        title: "Buy Confirmation",
        text: `Are you sure you want to buy now @ ${lotSummary.value.currencySymbol} ${
          type === 'moq' ? buyPrice : formatCurrency(lotSummary.value.buy_now_price || 0)
        }?`,
        price: type === 'moq' ? buyPrice : formatCurrency(lotSummary.value.buy_now_price || 0),
        lotType: type,
        qty: parseInt(lotQty),
        width: "500",
      };
    }

    // lotStore.placeBid(bidDetails)
  } else {
    authStore.setLoginDialog(true);
  }
};

const handleBidPrice = () => {
  lotStore.setError();
};

const closeLotDialog = (path, expired = false) => {
  lotDetailState.dialog = {
    type: "",
    popUp: false,
  };

  bid_finished.value = expired
  fetchDetails()

  let query = path === 'Products' ? 'BiddingMode' : 'mode';

  if (path) {
    router.push({ name: path, query: { [query]: queryParams.value?.bid_id ? 'Open' : 'Hybrid'} });
  }
};

const handleShowBuySummary = async () => {
  if (user.value?.authentication_token) {        
    const errored = await lotStore.getBuyDialogSummary(
      lotSummary.value.id,
      queryParams.value?.bid_id,
      lotDetailState?.dialog?.lotType === 'moq' ? lotDetailState?.dialog?.qty : ''
    );

    if(!errored) {
      lotDetailState.dialog = {
        type: "buy_summary",
        popUp: true,
        width: 900,
        lotType: lotDetailState?.dialog?.lotType,
        qty: lotDetailState?.dialog?.qty,
        price: lotDetailState?.dialog?.price
      };
    } else {
      lotDetailState.dialog = {
        type: "",
        popUp: false,
      };
    }
  } else {
    authStore.setLoginDialog(true);
  }
};

const alertStore = useAlertStore()
const userDetails = ref({});
const getUserData = () => {
  getUserDetails().then((res) => {
    if (res.status === 200) {
      userDetails.value = res.data.reseller_profile;
      console.log(userDetails.value);
      authStore.setProfileDetails(userDetails.value);
    } else {
      alertStore.setAlertMessage("error", res.data?.message, true);
    }
  });
};

const getRank = () => {
  return lotSummary.value.rank ? `${lotSummary.value.rank}` : "";
};

const handleReadMore = (type, readLess) => {
  let topOffset;
  if (type === "termElement") {
    lotDetailState.termView = {
      ...lotDetailState.termView,
      terms_of_purchase: readLess ? !lotDetailState.termView.terms_of_purchase : true,
      faq: false,
      itemCondition: false
    };
    topOffset = termElement.value.$el;
  } else if (type === "termConditionElement") {
    lotDetailState.termView = {
      ...lotDetailState.termView,
      terms_of_purchase: false,
      faq: false,
      itemCondition: readLess ? !lotDetailState.termView.itemCondition : true
    };
    topOffset = termConditionElement.value.$el;
  } else if (type === "itemConditionElement") {
    lotDetailState.termView = {
      ...lotDetailState.termView,
      itemCondition: readLess ? !lotDetailState.termView.itemCondition : true, 
      faq: false,
      terms_of_purchase: false
    };
    topOffset = itemConditionElement.value.$el;
  } else {
    lotDetailState.termView = {
      ...lotDetailState.termView,
      faq: readLess ? !lotDetailState.termView.faq : true,
      terms_of_purchase: false,
      itemCondition: false
    };
    topOffset = faqElement.value.$el
  }

  if(readLess) {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
    window.scrollTo(0,0)
  } else {
    setTimeout(()=>{
    var headerOffset = 50;
    var elementPosition = topOffset.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      })
    }, 1)
  }
};

const handleSubmitFeedBack = () => {
  if (user.value?.authentication_token) {
    let buyer_feedback = {
      reason: lotDetailState.feedBack,
    };
    lotStore.submitFeedbacks(
      route.params.id,
      buyer_feedback,
      queryParams.value?.bid_id
    );
  } else {
    authStore.setLoginDialog(true);
  }
};

let lotInterval;
watch(lotStore, async (newValue, oldValue) => {
  if (lotSummary.value?.id) {
    if(lotSummary.value.marketplace_id !== 'bb') {
      if (!lotDetailState.termsAdded && lotSummary.value.marketplace_id) {
        let tp = await fetchScbTermsOfPurchase(lotSummary.value.marketplace_id);
        if(tp?.status_code !== 404){
          tp.title = tp?.terms_and_conditions?.slice(0,100);
          lotDetailState.termsOfPurchase = tp
        }
        lotDetailState.termsAdded = true;
      }

      if (!lotDetailState.faqAdded && lotSummary.value.marketplace_id) {
        let tp = await fetchScbFaq(lotSummary.value.marketplace_id);
        if(tp?.status_code !== 404){
          tp.title = tp?.faq?.slice(0,100)
          lotDetailState.FAQ = tp;
        }
        lotDetailState.faqAdded = true;
      }
    } else {
      lotDetailState.termsAdded = true;
      lotDetailState.termsOfPurchase.title = "Blubirch is an intermediary that provides a platform, including the Site and Services, through which Buyers may purchase Inventory from Sellers"
      lotDetailState.faqAdded = true;
    }

    let StartBidTime = new Date(lotSummary.value?.start_date).getTime();
    let EndBidTime = new Date(lotSummary.value?.end_date).getTime();

    bid_not_started.value =
      lotSummary.value?.status === statusList.NotStarted ||
      lotSummary.value.futureBid;
    bid_finished.value =
      lotSummary.value?.status === statusList.InProgress ||
      lotSummary.value?.status === statusList.InProgress_2 ||
      lotSummary.value?.status === statusList.NotStarted
        ? !lotSummary.value.bidRemainingTime
        : true;

    lotInterval = setInterval(() => {
      const now = new Date().getTime();
      bid_not_started.value = lotSummary.value.futureBid;
      bid_finished.value =
        ((lotSummary.value?.status === statusList.InProgress) || (lotSummary.value?.status === statusList.InProgress_2))
          ? !lotSummary.value.bidRemainingTime
          : true;

      const { status, start_date, end_date } = lotSummary.value;
      if (isRefetch(status, end_date, start_date)) {
        if ((statusList.InProgress === status) || (statusList.InProgress_2 === status) || (statusList.NotStarted === status)) {
          if (!detailsInterval && error.type !== 'lotDetailsError') {
            detailsInterval = setInterval(() => {
              fetchDetails(true);
            }, 15000);
          }
        }
      } else {
        clearInterval(detailsInterval);
      }
    }, 1000);
  }

  if (newValue.error.id === "bid_price_error") {
    lotDetailState.dialog = {
      type: "",
      popUp: false,
    };
  }

  if (newValue.success.id === "buyer_feedbacks") {
    lotDetailState.buyer_feedback = newValue.success.message;
  }

  if (newValue.success.id === "bid_price_submit") {
    lotDetailState.dialog = {
      type: "successBid",
      popUp: true,
      bidPrice: lotSummary.value.bid_price,
      width: 500,
    };
    lotDetailState.bidPrice = "";
  }
  if (newValue.success.id === "checkout_submit") {
    lotDetailState.dialog = {
      type: "buySuccessfully",
      popUp: true,
      width: 900,
    };
  }

  if (newValue.success.id === "send_manifestURL_success") {
    lotDetailState.dialog = {
      type: "manifest_popup",
      popUp: true,
      message: newValue.success.message,
      title:
        newValue.success.manifestType === "whatsapp"
          ? "Message Sent"
          : "Email Sent",
      width: 500,
    };
  }
});
</script>
