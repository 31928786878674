<template>
  <v-card class="bck-color mb-2 mt-2 align-start lots-box" variant="none">
    <!-- email and whatsapp manifest -->
    <v-row class="bck-color mt-9 hidden-sm-and-down">
      <v-col cols="7" class="bck-color">
        <v-card-title class="font-weight-bold">ALL PRODUCTS</v-card-title>
      </v-col>
      <v-col cols="5" class="d-flex align-end text-end justify-end bck-color">
        <v-btn
          color="blue"
          variant="outlined"
          class="bid-buy-button text-caption mb-5 mr-2"
          @click="handleManifest('email')"
          >Email Manifest</v-btn
        >
        <v-btn
          color="blue"
          class="bid-buy-button text-caption ml-2 mb-5"
          variant="flat"
          download
          @click="handleManifest('csvDownload')"
          >Download Manifest</v-btn
        >
        <v-btn
          prepend-icon="mdi-whatsapp"
          color="green"
          class="bid-buy-button text-caption ml-2 mb-5"
          variant="flat"
          @click="handleManifest('whatsapp')"
          >Whatsapp</v-btn
        >
      </v-col>
    </v-row>

    <!--  email and whatsapp manifest for mobile screens-->
    <v-row class="d-lg-none d-md-none d-sm-block d-xs-block d-block">
      <v-col>
        <v-text>ALL PRODUCTS</v-text>
        <div>
          <v-btn
            color="blue"
            variant="outlined"
            width="100%"
            class="bid-buy-button text-caption mt-3 mb-5"
            @click="handleManifest('email')"
            >Email Manifest</v-btn
          >

          <v-btn
            color="blue"
            class="bid-buy-button text-caption mb-5"
            width="100%"
            variant="flat"
            download
            @click="handleManifest('csvDownload')"
            >Download Manifest</v-btn
          >
          <v-btn
            prepend-icon="mdi-whatsapp"
            color="green"
            class="bid-buy-button text-caption mb-5"
            variant="flat"
            width="100%"
            @click="handleManifest('whatsapp')"
            >Whatsapp</v-btn
          >
        </div>
      </v-col>
    </v-row>

    <!-- table for web screens -->
    <v-card-item class="d-lg-block d-md-block d-sm-block d-xs-none d-none">
      <template v-if="!loader.productsLoader">
        <v-data-table
          :headers="manifestTableState.headers"
          :items="items"
          :items-per-page="paginationstore.perPage"
          hide-default-footer
          class="elevation-1"
        >
          <template v-slot:[`item.image_urls`]="{ item }">
            <v-img
              :src="item.raw.image_urls?.[0] || lotImage"
              alt="lot img not found"
              class="w-100-60 rounded-lg my-2"
            />
          </template>
          <template v-slot:bottom>
            <template v-if="totalPages">
              <v-pagination
                v-model="page"
                :length="totalPages"
                :total-visible="6"
              ></v-pagination>
            </template>
          </template>
        </v-data-table> 
      </template>
      <template v-else="loader.productsLoader">
        <Skeleton />
      </template>
    </v-card-item>

    <!-- table responsive for mobile screens -->
    <v-row
      class="bck-color mt-2 mb-2 d-lg-none d-md-none d-sm-none d-xs-block d-block"
    >
      <template v-if="!loader.productsLoader">
        <v-col cols="12" v-for="(item, index) in items" :key="index">
          <v-card class="mx-auto product-card" max-width="400">
            <v-img
              class="align-end text-white"
              height="200"
              :src="getImage(item?.image_urls?.[0])"
              cover
            >
            </v-img>
            <v-card-title>{{ item.brand }}</v-card-title>

            <v-card-subtitle class="pt-4">
              {{ item.category }}
            </v-card-subtitle>

            <v-card-subtitle class="pt-4">
              Quantity: {{ item.quantity }}
            </v-card-subtitle>


            <v-card-text>
              <div>{{ item.description }}</div>
            </v-card-text>

            <v-card-actions v-if="configuration.showMRP">
              <v-btn color="black"> {{lotSummary.benchmark_price == "mrp" ? configuration.amountLabel : lotSummary.benchmark_price.toUpperCase()}} </v-btn>

              <v-btn color="black"> {{(item.mrp)}} </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
        <template v-if="totalPages">
          <v-pagination
            v-model="page"
            :length="totalPages"
            :total-visible="1"
          ></v-pagination>
        </template>
      </template>
      <template v-else="loader.productsLoader">
        <Skeleton />
      </template>
    </v-row>
  </v-card>
</template>

<script setup>
import { computed, onMounted, reactive, watch } from "vue";
import { uselotProducts, usePaginationStore, useAuth, loaderStore, useMetaStore } from "../../stores";
import { useRoute } from "vue-router";
import { formatCurrency } from "../../misc/formatter.js";
import lotImage from "@/../assets/images/Box.svg";
import Skeleton from "../TableSkeleton/index.vue";
import { manifestTableHeader } from "../../static";
import configuration from "../../config.json";

const lotStore = uselotProducts();
const paginationstore = usePaginationStore();
const authStore = useAuth();
const loader = loaderStore();
const metaStore = useMetaStore();
const route = useRoute();

const items = computed(() => lotStore.allProductsForDetailsPage);
const lotSummary = computed(() => lotStore.lotSummary);
const user = computed(() => authStore.user);
const profile = computed(() => authStore.profile);

const headers = configuration.showMRP
  ? manifestTableHeader.concat({ title: lotSummary.value.benchmark_price ? lotSummary.value.benchmark_price.toUpperCase() : configuration.amountLabel, key: 'mrp' })
  : manifestTableHeader;

const manifestTableState = reactive({
  headers: headers
});

const getImage = (img = "") => {
  return img ? img : lotImage;
};

const page = computed({
  get: () => paginationstore.productPage,
  set: (value) => {
    paginationstore.setProductPage(value);
    if (route.query.bid_id) {
      lotStore.getAllSCBProducts(route.params.id);
    } else {
      if(metaStore.marketplaceContent === 'private' && !user?.value?.authentication_token){

      }else{
        lotStore.getAllProducts(route.params.id, route.query?.uuid);
      }
    }
  },
});
const totalPages = computed(() => paginationstore.productPages);

onMounted(() => {
  if (route.query.bid_id) {
    lotStore.getAllSCBProducts(route.params.id);
  } else {
    if(metaStore.marketplaceContent === 'private' && !user?.value?.authentication_token){
    }else{
      lotStore.getAllProducts(route.params.id, route.query?.uuid);
    }
  } 
});

const handleManifest = (manifest) => {
  let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.[a-z-]+$/;
  let numberRegex = /^[0-9-]{10}$/;
  if (manifest === "email") {
    let email = prompt(
      "Manifest link will be sent to your email address : ",
      profile?.value?.email || ""
    );
    if (email == null || email == "") {
      return;
    }else if(email.match(emailRegex)){
      let detail = {
        to: email,
        download_url: lotSummary.value.manifest_url,
      };
      lotStore.sendManifestURL(
        route.params.id,
        detail,
        "email",
        route.query.bid_id
      );
    }else{
      alert("Enter valid Email address.")
    }
  } else if (manifest === "csvDownload") {
    window.open(lotSummary.value.manifest_url, "_blank");
    lotStore.trackManifestDownloads(route.query.bid_id, route.params.id)
  } else {
    let number = prompt(
      "Manifest link will be sent to your whatsapp number : ",
      profile?.value?.mobile || ""
    );
    if (number == null || number == "") {
      return;
    } else if(number.match(numberRegex)) {
      let detail = {
        to: number,
        download_url: lotSummary.value.manifest_url,
      };
      lotStore.sendManifestURL(
        route.params.id,
        detail,
        "whatsapp",
        route.query.bid_id
      );
    }else{
      alert("Enter valid whatsapp number.")
    }
  }
};

watch(() => paginationstore.productPages, () => {
  totalPages.value = paginationstore.productPages
})
</script>
