<template>
  <div class="filter-sidebar">
    <div class="d-flex justify-space-between box">
      <p>FILTERS</p>
      <small @click="handleFilterClear" class="cursor-pointer">Clear</small>
    </div>
    <div>
      <v-expansion-panels v-model="panel" multiple>
        <v-expansion-panel v-if="configuration.Lots.Open.enabled && filterState.BiddingMode !== 'moq' && marketplaceContent !== 'private'">
          <v-expansion-panel-title>BIDDING MODE</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-radio-group v-model="filterState.BiddingMode">
              <v-radio v-for="bidding in biddings" :value-comparator="handleSelect" :key="bidding.label" :disabled="pageLoading.isLoading" :label="bidding.label" :value="bidding.value"
                ></v-radio>
            </v-radio-group>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-title>PRICE RANGE </v-expansion-panel-title>
          <v-expansion-panel-text class="mt-6 range-filter">
            <!-- <div class="d-flex justify-space-evenly text-caption">
              <p>{{ min }}</p>
              <p>{{ max }}</p>
            </div>
            <v-slider
              v-model="filterState.priceRange"
              color="blue-darken-4 flex-grow-1"
              :min="min"
              :step="1000"
              :show-ticks="always"
              :max="max"
            ></v-slider> -->
            <v-range-slider v-model="filterState.priceRange" color="blue-darken-4 flex-grow-1" :min="0" :max="filterStore.priceRange?.[1] ?? 12000000" step="100000"
              thumb-label="always"></v-range-slider>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel v-if="categoryElements && categoryElements?.length > 0">
          <v-expansion-panel-title>CATEGORY</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-checkbox hide-details="true" v-model="filterState.category_id" v-for="categoryElement in categoryElements"
              :key="categoryElement.label" :label="categoryElement.label" :value="categoryElement.value"
              multiple></v-checkbox>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel class="sub-category" v-if="subCategoryElements && subCategoryElements?.length > 0">
          <v-expansion-panel-title>SUB CATEGORY</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-checkbox v-model="filterState.sub_categories" v-for="subcategoryElement in subCategoryElements"
              :key="subcategoryElement.label" :label="subcategoryElement.label"
              :value="subcategoryElement.value"></v-checkbox>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel v-if="conditionElements && conditionElements?.length > 0">
          <v-expansion-panel-title>CONDITION</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-checkbox v-model="filterState.condition" v-for="conditionElement in conditionElements" :key="conditionElement.label"
              :label="conditionElement.label" :value="conditionElement.value"></v-checkbox>
          </v-expansion-panel-text>
        </v-expansion-panel>

        <v-expansion-panel v-if="locationElements && locationElements?.length > 0">
          <v-expansion-panel-title>LOCATION</v-expansion-panel-title>
          <v-expansion-panel-text>
            <v-checkbox v-model="filterState.location" v-for="locationElement in locationElements" :key="locationElement.label"
              :label="locationElement.label" :value="locationElement.value"></v-checkbox>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
  import app from './index.js';

  export default app;
</script>