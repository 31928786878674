<template>
  <v-menu transition="slide-y-transition" v-if="user?.authentication_token">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        class="ma-2 hidden-md-and-down username-box"
        append-icon="mdi-menu-down"
        variant="outlined"
        >{{ user.username }}</v-btn
      >
    </template>
    <v-list>
      <v-list-item
        title="My Bids"
        value="My Bids"
        link="true"
        :to="marketplaceContent === 'private' ? `/${marketplaceName}/my_bids?mode=Hybrid` : '/my_bids?mode=Hybrid'"
      ></v-list-item>
      <v-list-item
        title="My Orders"
        value="My Orders"
        link="true"
        :to="marketplaceContent === 'private' ? `/${marketplaceName}/my_orders?mode=Hybrid` : '/my_orders?mode=Hybrid'"
      ></v-list-item>
      <v-list-item
        title="My Profile"
        value="My Profile"
        link="true"
        @click="authStore.setProfileDialog(true)"
      ></v-list-item>
      <v-list-item
        title="Change Password"
        value="Change Password"
        @click="handleChangePassword"
      ></v-list-item>
      <v-list-item @click="handleSignOut">
        <v-list-item-title>Sign Out</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>

  <v-dialog v-model="dialog" width="500" v-else>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" class="ma-2 hidden-sm-and-down" variant="outlined"
        >SIGN IN</v-btn
      >
    </template>
    <v-card class="pa-5" v-model="isClose">
      <v-row>
        <v-col cols="2">
          <v-btn icon variant="text" @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="10">
          <v-card-title
            class="d-flex justify-start align-center text-center mb-3"
          >
            Login to your Account</v-card-title
          >
        </v-col>
      </v-row>
      <form @submit.prevent="submit" class="login-form">
        <v-text-field
          :width="100"
          id="phone"
          name="phone"
          v-model="phone.value.value"
          :error-messages="phone.errorMessage.value"
          label="Username / Mobile Number"
          variant="outlined"
          :disabled="otpSent"
          class="flex-1-1-100"
          @input="handleChange"
        ></v-text-field>

        <template v-if="!otpSent">
          <v-text-field
            v-model="password.value.value"
            :error-messages="password.errorMessage.value"
            :type="passwordVisible ? 'text' : 'password'"
            label="Password"
            variant="outlined"
            :append-inner-icon="
              passwordVisible ? 'mdi-eye-outline' : 'mdi-eye-off-outline'
            "
            @click:append-inner="passwordVisibility"
          ></v-text-field>
        </template>
        <template v-else>
          <v-otp-input
            ref="otpInput"
            v-model:value="bindModal"
            class="justify-center"
            input-classes="otp-input"
            separator=""
            :num-inputs="6"
            :should-auto-focus="true"
            input-type="letter-numeric"
            :conditionalClass="['one', 'two', 'three', 'four']"
            @on-change="handleOnChange"
          />
        </template>

        <div  v-if="otpSent && otpVerified" class="d-flex justify-space-between no-padding mb-2 mt-2 timer-action">
          <span class="green">
            <v-icon
              size="small"
              icon="mdi mdi-check"
              color="#14ae5c"
            ></v-icon>

            Verified
          </span>

        </div>

        <template v-if="otpSent">
          <div class="d-flex justify-space-between no-padding mb-2 mt-2 timer-action">
            <span v-if="countDown > 0">
              <img :src="getTimerImage()" alt="timer" /> {{countDown}}
            </span>

            <span v-if="countDown < 1" class="blue text-decoration-underline" @click="resendOTP">
              Resend OTP
            </span>

          </div>
        </template>

        <template v-else>
          <v-card-Subtitle class="d-flex align-end text-end justify-end text-decoration-underline mb-2"
            ><span @click="handleForgotPassword" class="blue">
              Forgot Password</span
            ></v-card-Subtitle>
        </template>

        <!-- <div class="d-flex flex-row">
          <v-checkbox
            color="info"
            @click="dataState.isDisabled = !dataState.isDisabled"
            value="1"
          >
          </v-checkbox>
          <v-text-field 
            :width="100"
            v-model="phone.value.value"
            :error-messages="phone.errorMessage.value"
            maxLength="10"
            label="Enter the phone number"
            variant="outlined"
            :disabled="dataState.isDisabled"
            class="flex-1-1-100"
          >
          </v-text-field>
        </div> -->

        <!-- <div class="d-flex flex-row pb-0">
          <v-checkbox color="info" v-model="dataState.checkbox" type="checkbox">
          </v-checkbox>
          <v-card-text :width="100" class="flex-1-1-100 px-0"
            >I agree to the updated
            <a class="cursor-pointer"
              ><span class="blue"
                ><router-link to="/terms-n-conditions" @click="dialog = false"
                  >Terms and conditions</router-link
                ></span
              ></a
            >
            and
            <a class="cursor-pointer"
              ><span class="blue"
                ><router-link to="/privacypolicy" @click="dialog = false">
                  privacy policy
                </router-link></span
              ></a
            >
            <p class="error-message login-checkbox-error">
              {{ checkboxError }}
            </p>
          </v-card-text>
        </div> -->

        <div>
          <v-btn
            class="bg-theme-primary"
            type="submit"
            size="large"
            width="100%"
            :disabled="otpSent ? !(phone.value.value && otpFilled) : false"
          >
            Login
          </v-btn>

          <div class="d-flex flex-row pt-3">
            <v-divider class="mt-3"> </v-divider>
            <div class="mb-3">OR</div>
            <v-divider class="mt-3"> </v-divider>
          </div>

          <template v-if="otpSent">
            <v-btn
              color="blue"
              variant="outlined" 
              size="large"
              width="100%"
              :disabled="!phoneEnable"
              @click="handlePasswordLogin"
            >
              Login Through Password
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              color="blue"
              variant="outlined" 
              size="large"
              width="100%"
              :disabled="!phoneEnable"
              @click="sendOtp"
            >
              Login Through OTP
            </v-btn>
          </template>
          <v-card-text class="d-flex align-center text-center justify-center" v-if="marketplaceContent !== 'private'">
            Dont have an account?
            <span
              class="blue text-decoration-underline"
              color="blue"
              @click="() => handleRedirect('SignUp')"
              >&nbsp;Sign Up
            </span>
          </v-card-text>
        </div>
      </form>
    </v-card>
  </v-dialog>
</template>

<script src="./login.js" />